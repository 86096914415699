import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { interval, merge, Observable, of as observableOf, of, Subject } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil, takeWhile, timeout } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { PermissionService } from 'src/app/permission.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from 'src/app/Organization_Management/organization-service.service';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.css']
})
export class PendingComponent implements OnInit , AfterViewInit {
  private stopPolling = new Subject<void>();

  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userData = [];
  alerts =[];
  currentOrg: any;
  newLinktoAllPendingUsersButtonClicked = false;
  displayedColumns: string[] = ['status', 'lastname', 'firstname', 'email', 'action1', 'action2'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  displayStyle = 'none';
  filteredAndPagedIssues: Observable<[]>;
  filter = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('closebutton') closebutton;
  orginalData: any;

  public constructor(private organizationService: OrganizationService,private translateService: TranslateService,private commonService: CommonService, private router: Router,private permission: PermissionService) {
    this.commonService.getCurrentOrg().subscribe(
      data => {
        if(data.body !=null)
          {
            this.currentOrg = data.body;
            this.commonService.selectedOrg = data.body;
        }
      }, error => {}
    );
  }
  
  authorized() {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  isHelpDeskAdmin()
  {
    return this.permission.isHelpDeskAdmin;
  }
  ngAfterViewInit() {

    this.apiTrigger();
  }
  navigateUserDetail(row)
  {

        this.router.navigate(['/userrequest/' + row._id ])
        .then(nav => {
          this.commonService.previoususerlistlocation = '/userrequest/list';
           // true if navigation is successful
        }, err => {
           // when there's an error
        });
      
   
  }
  apiTrigger()
  {
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        let request;
        if(Object.keys(this.filter).length>0)
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
         else
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};
         return this.commonService.getPendingUserData(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = data.attr.total;
        this.orginalData = data.users;
        return data.users;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  
  resetPaging(): void {
    this.apiTrigger();
  }
  activateAllUsers()
  {
    this.newLinktoAllPendingUsersButtonClicked = true;
    this.commonService.activateAllUser().subscribe( data =>
      {
      this.apiTrigger();
      this.closebutton.nativeElement.click();

    });
  }
  applyFilterbyLastName(filterValue: string) {
   this.filter[`sn`] = filterValue!=""?filterValue.toLowerCase():"";
   this.apiTrigger();
   this.paginator.firstPage();

  }
  applyFilterbyFirstName(filterValue: string) {
    this.filter[`givenName`] = filterValue!=""?filterValue.toLowerCase():"";
    this.apiTrigger();
    this.paginator.firstPage();

  }
  applyFilterbyEmail(filterValue: string) {
    this.filter[`mail`] = filterValue!=""?filterValue.toLowerCase():"";

    this.apiTrigger();
    this.paginator.firstPage();

}
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: false
    };
  }
  deleteOrganizationUser(row)
  {
    this.stopPolling = new Subject<void>();
   
    let request ={
      "orgID":"",
      "userID":""
      }
      this.commonService.usersbymail(row.mail).subscribe(data =>{
        if(data!=null)
        {
          request.userID=data._id;
      request.orgID =this.commonService.selectedOrg[`id`];
      this.isLoadingResults = true;
  
      this.organizationService.deletestatusUserbyOrg(request).subscribe(data =>{   
        const startTime = Date.now();
        interval(5000).pipe(
          switchMap(() => this.commonService.checkEmailfromorg(row.mail, request.orgID)),
          takeUntil(this.stopPolling),
  
          filter(searchResponse => searchResponse !== null), // Ensure we only process valid responses
          catchError(error => {
            console.error('Error during search user polling:', error);
            return of(null);
          }),
          timeout(60000)
        ).subscribe(searchResponse => {
          const elapsedTime = Date.now() - startTime;
          if (searchResponse && searchResponse==="false") {
            this.apiTrigger();
            this.stopPolling.next();
            this.stopPolling.complete();
          } else if (elapsedTime >= 60000){
            this.isLoadingResults = false;
            this.stopPolling.next();
            this.stopPolling.complete();
            console.error('User deletion process exceeded the threshold limit.');
          }
        });
    },
    err=>{
      this.isLoadingResults = false;
  
    }
  );
        }
      });
      
  }
closeAlert(index) {
  this.alerts.splice(index, 1);
}
sendNewLink(row)
{
  
  const request = {
    "mail":row.mail,
    "orgId":this.commonService.selectedOrg[`id`],
    "sendEmail":true
};

  this.commonService.activateUser(request).subscribe(data =>
    {
      this.filter = {};
      this.translateService.get('views.js.alerts.userrequest.The new activation mail has been sent.').subscribe(res => {
        //our alert message 
        this.alerts.push({type: 'info', msg: res});
      });
      this.apiTrigger();
    },
    err =>
    {
    }
    );
}
  gotoHomePage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoUserRequestNewPage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate(['userrequest/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
}

