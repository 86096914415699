import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { interval, merge, Observable, of as observableOf, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, takeWhile, timeout } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { PermissionService } from 'src/app/permission.service';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationService } from 'src/app/Organization_Management/organization-service.service';
import { FormControl } from '@angular/forms';
declare let $: any;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, AfterViewInit {

  dtOptions: DataTables.Settings = {};
  userData;
  organization: any;
  table = $('#userTable').DataTable();
  displayedColumns: string[] = ['status', 'lastname', 'firstname', 'email', 'action'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  filteredAndPagedIssues: Observable<[]>;
  filter = {};
  searchLastNameControl=new FormControl();
  searchFirstNameControl=new FormControl();
  searchEmailControl=new FormControl();
  private stopPolling = new Subject<void>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    // this.setDataSourceAttributes();
  }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  originalData: any;
  public constructor(private organizationService:OrganizationService,private commonService: CommonService, private router: Router,private permission: PermissionService) {
    this.commonService.getCurrentOrg().subscribe(
      data => {
        if(data.body !=null)
          {
            this.organization = data.body;
            this.commonService.selectedOrg = data.body;
        }
      }, error => {}
    );
    this.searchFirstNameControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      
      this.filter['givenName'] = newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
      this.paginator.firstPage();

    });
    this.searchLastNameControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['sn'] = newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
      this.paginator.firstPage();
    });
    this.searchEmailControl.valueChanges
    .pipe(
      debounceTime(300), // Delay of 300 ms
      distinctUntilChanged() // Only emit when the current value is different from the last
    )
    .subscribe(newValue => {
      this.filter['mail'] =  newValue!=""?newValue.toLowerCase():"";
      this.apiTrigger(); // Trigger your method here
      this.paginator.firstPage();

    });
  }
  authorized() {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin || this.permission.isHelpDeskAdmin;
  }
  isHelpDeskAdmin()
  {
    return this.permission.isHelpDeskAdmin;
  }
  ngAfterViewInit() {

    this.apiTrigger();
  }
 
  navigateUserDetail(row)
  {
    
        this.router.navigate(['/user/' + row._id ])
        .then(nav => {
          this.commonService.previoususerlistlocation = '/user/list';
           // true if navigation is successful
        }, err => {
           // when there's an error
        });
      
 
   
  }
  apiTrigger()
  {
    this.filteredAndPagedIssues = merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        let request;
        if(Object.keys(this.filter).length>0)
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'filters': this.filter, 'size': this.paginator.pageSize};
         else
          request = { 'page': this.paginator.pageIndex + 1, 'order': this.sort.direction, 'orderBy': this.sort.active, 'size': this.paginator.pageSize};
         return this.commonService.getUserData(request);
      }),
      map(data => {
        // Flip flag to show that loading has finished.

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        const users = this.checkStatus(data.users,this.commonService.selectedOrg[`id`]);
        this.resultsLength = data.attr.total;
        this.originalData = users;
        return users;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        // Catch if the GitHub API has reached its rate limit. Return empty data.
        this.isRateLimitReached = true;
        return observableOf([]);
      })
    );
  }
  getStatusForOrg(userRelationship: any, orgId: string): string {
    // Check if the orgId exists at the top level
    if (userRelationship[orgId] && userRelationship[orgId].status) {
      return userRelationship[orgId].status;
    }

    // Traverse nested objects
    for (const key in userRelationship) {
      if (userRelationship.hasOwnProperty(key)) {
        const value = userRelationship[key];
        if (typeof value === 'object' && value[orgId] && value[orgId].status) {
          return value[orgId].status;
        }
      }
    }

    // If orgId is not found
    return null;
  }
  checkStatus(data,orgId)
  {
    return data.map(user => {
      const status = this.getStatusForOrg(user.userRelationship, orgId);
      if(status!=null)
      {
      return {
        ...user,
        accountStatus: status
      };
    }
    });
  }
  resetPaging(): void {
    this.apiTrigger();
  }
 
suspendOrganizationUser(row)
{
  
  let request ={
    "email":"",
  "orgId":"",
  "status":""
    }
    request.email=row.mail;
    request.orgId =this.commonService.selectedOrg[`id`];
    request.status = "suspended";
  this.organizationService.updatestatusUserbyOrg(request).subscribe(data =>{   
    this.apiTrigger();
  });

}
deleteOrganizationUser(row)
{
  this.stopPolling = new Subject<void>();
 
  let request ={
    "orgID":"",
    "userID":""
    }
    this.commonService.usersbymail(row.mail).subscribe(data =>{
      if(data!=null)
      {
        request.userID=data._id;
    request.orgID =this.commonService.selectedOrg[`id`];
    this.isLoadingResults = true;

    this.organizationService.deletestatusUserbyOrg(request).subscribe(data =>{   
      const startTime = Date.now();
      interval(5000).pipe(
        switchMap(() => this.commonService.checkEmailfromorg(row.mail, request.orgID)),
        takeUntil(this.stopPolling),

        filter(searchResponse => searchResponse !== null), // Ensure we only process valid responses
        catchError(error => {
          console.error('Error during search user polling:', error);
          return of(null);
        }),
        timeout(60000)
      ).subscribe(searchResponse => {
        const elapsedTime = Date.now() - startTime;
        if (searchResponse && searchResponse==="false") {
          this.apiTrigger();
          this.stopPolling.next();
          this.stopPolling.complete();
        } else if (elapsedTime >= 60000){
          this.isLoadingResults = false;
          this.stopPolling.next();
          this.stopPolling.complete();
          console.error('User deletion process exceeded the threshold limit.');
        }
      });
  },
  err=>{
    this.isLoadingResults = false;

  }
);
      }
    });
    
}
activateOrganizationUser(row)
{
  
  let request ={
    "email":"",
  "orgId":"",
  "status":""
    }
    request.email=row.mail;
    request.orgId =this.commonService.selectedOrg['id'];
    request.status = "active";
  this.organizationService.updatestatusUserbyOrg(request).subscribe(data =>{   
    this.apiTrigger();
  });
}
getPageDetails(event) {
  this.apiTrigger();
}
  ngOnInit(): void {
    
      
  
  this.commonService.getCurrentOrg().subscribe(
    data => {
      if(data.body !=null)
        {
          this.organization = data.body;
          this.commonService.selectedOrg = data.body;
      }
    }, error => {}
  );
}
  gotoHomePage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate([''])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoUserRequestNewPage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate(['userrequest/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

}
