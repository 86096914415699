<div class="deleteConfirmation">
    <div *ngIf="!isDeleting" >
        <button type="button" [attr.disabled]="isHelpDeskAdmin()==true ? '':null" title="Delete" (click)="startDelete()"><i class="fa"></i>
            <span class="glyphicon glyphicon-trash" style="color:black;"></span></button>
    </div>
    <div *ngIf="isDeleting">Delete?
    <button type="button" title="Confirm Delete" (click)="confirm()">Yes</button>
    <button type="button"  title="Cancel Delete" (click)="cancel()">No</button>
    </div>
    </div>
