import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PermissionService } from '../permission.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {

  isDeleting = false;
  @Output() onconfirm = new EventEmitter<string>();
  constructor(private permission: PermissionService) { }

  ngOnInit(): void {
  }

  startDelete()
  {
    this.isDeleting = true;
  }
  confirm()
  {
    this.onconfirm.emit();
  }
  cancel()
  {
    this.isDeleting = false;
  }
  isHelpDeskAdmin()
  {
    return this.permission.isHelpDeskAdmin;
  }
}
