<div id="roleList" [hidden]="!(authorized())">

    <ol class="breadcrumb breadcrumb-dis">

        <li><a [routerLink]="['']">{{'views.role.Home' | translate }}</a></li>
        <li class="active">{{'views.roleassignmentorg-list.Role Assignments for'| translate}} {{currentOrganization}}
        </li>
        <li class="logout"><a href="./oidc/logout">{{'views.role.Log out' | translate }}</a></li>
    </ol>

    <div class="titleicon"><img src="assets/images/homepage/roles.png" alt="role management" /></div>
    <h1>{{'views.roleassignmentorg-list.Role Assignments for'| translate}} {{currentOrganization}}</h1>
<!-- 
    <div class="btn-group btn-group-xs" style="display: flex;justify-content: end;background: #ddd;">
        <div class="btn-group btn-group-xs">
            <button type="button" class="btn btn-sm" (click)="showAllRoleAssignments()">{{alltxt}}</button>
        </div>
        <div class="btn-group btn-group-xs">
            <a class="btn btn-primary" download="roles.csv">CSV</a>
        </div>
    </div> -->

    <div class="panel panel-default" style="overflow: scroll;">
        <mat-form-field appearance="standard" style="margin:0 218px">
            <mat-label>Search by Email</mat-label>
            <input matInput [formControl]="email" placeholder="Ex. name@example.com">

            <mat-error *ngIf="email.hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="email.hasError('email')">
                Please enter a valid email
            </mat-error>
        </mat-form-field>
        <div class="spinner" *ngIf="spinnerFlag"></div>
        <span class="" *ngIf="email.touched && resultsLength < 1" style="margin:109px 175px">
            {{'views.user.The user has no role assignments.' | translate}} <br />
        </span>
        <div >

            <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort matSortActive="email"
                matSortDisableClear matSortDirection="asc" (matSortChange)="resetPaging()">
                <!-- (matSortChange)="resetPaging()" -->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                        style="font-weight: bold; color: black;">{{'views.user.Status'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <img style="width: 25px" *ngIf="row.status === 'suspended'"
                            src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                            title="{{'views.user.suspended' | translate}}" />
                        <img style="width: 25px" *ngIf="row.status === 'active'"
                            src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                            title="{{'views.user.active' | translate}}" />
                    </td>
                </ng-container>
                <!-- {
                    "userId": {
                        "id": 10,
                        "lastname": "Savitha",
                        "firstname": "B",
                        "email": "bsavitha@deloitte.com",
                        "status": "active",
                        "userType": "external"
                    }
                } -->

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                        style="width: fit-content; font-weight: bold; color: black;">{{'views.user.Role' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{row?.roleName}}</td>
                </ng-container>

                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                        style="font-weight: bold; color: black;">
                        Organization</th>
                    <td mat-cell *matCellDef="let row">{{row?.orgName}}</td>
                </ng-container>
                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear
                        style="font-weight: bold; color: black;">
                        Target Service</th>
                    <td mat-cell *matCellDef="let row">{{row?.technicalId}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="row.status  !== 'suspended'" style="display: flex;">
                            <app-confirmsuspend (onconfirm)="suspendRole(row)"></app-confirmsuspend>
                            <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>

                        </div>
                        <div *ngIf="row.status  === 'suspended'" style="display: flex;">
                            <app-confirm-activate (onconfirm)="activateRole(row)"></app-confirm-activate>
                            <app-confirm-delete (onconfirm)="deleteRole(row)"></app-confirm-delete>
                        </div>
                    </td>
                </ng-container>
               
                <ng-container matColumnDef="filter-status">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

               
                <ng-container matColumnDef="filter-role">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput [formControl]="searchRoleControl" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-organization">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput [formControl]="searchOrglControl" />
                        </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-technicalId">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-form-field appearance="outline">
                            <input matInput [formControl]="searchtechnicalControl" />
                        </mat-form-field>
                    </th>
                </ng-container>
                <ng-container matColumnDef="filter-action">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                </ng-container>

                <!-- <ng-container matColumnDef="filter-email">
                    <th mat-header-cell *matHeaderCellDef> <mat-form-field appearance="outline">
                            <input matInput (keyup)="applyFilterbyEmail($event.target.value)" />
                        </mat-form-field> </th>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-header-row
                    *matHeaderRowDef="['filter-status', 'filter-role', 'filter-organization','filter-technicalId','filter-action']"
                    class="example-second-header-row">
                </tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"></mat-paginator>
    </div>
    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" class="btn btn-default" (click)="gotoHomePage()">
                <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>{{'views.role.Home' | translate
                }}</button>
        </div>
        <div class="btn-group">
            <button type="button" class="btn btn-default" (click)="gotoRoleNewPage()">
                <span class="glyphicon glyphicon-plus"
                    style="margin-right: 10px"></span>{{'views.roleassignmentorg-list.Assign a role' |
                translate }}</button>
        </div>
    </div>
    <app-terms-conditions></app-terms-conditions>

</div>