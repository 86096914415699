import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../common.service';
import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from 'src/app/permission.service';

@Component({
  selector: 'app-organization-batch',
  templateUrl: './organization-batch.component.html',
  styleUrls: ['./organization-batch.component.css']
})
export class OrganizationBatchComponent  {
  // readmore variable, its true than read more string will print
  ReadMore = true;

  // hiding info box
  visible = false;
  selected = false;
  showprogress = false;
  alerts = [];
  progresswidth;
  selectedfile = {};
  progressmax = 0;
  progressvalue = 0;
  emailLinkValidityDurationHours = 123;
  upload() {
    this.alerts = [];
    this.progressmax = this.selectedfile[`size`];
    this.progressvalue = 0;
    this.progresswidth = '0';
    this.showprogress = true;
    this.commonService.batchOrganizationUpload(this.selectedfile).subscribe(
      (event: HttpEvent<Blob>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            const percentDone = Math.round(100 * event.loaded / event.total);
            if (event.loaded > this.progressmax) {
              this.progressmax = event.loaded;
            }
            this.progressvalue = event.loaded;
            this.progresswidth = (this.progressvalue / this.progressmax) * 100 + '%';
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            break;
          case HttpEventType.Response:
            this.translateService.get('views.js.alerts.user.Uploaded file. An email will be sent once the changes have been processed.').subscribe(res => {
              this.alerts.push({ type: 'info', msg: res });
            });
            this.selectedfile = '';
            this.selected = false;
        }
      },
      (err: HttpErrorResponse) => {
        this.translateService.get('views.js.alerts.user.An error occured while uploading the file.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: res });
        });
      }
    );
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  showHelp() {
    this.ReadMore = !this.ReadMore; // not equal to condition
    this.visible = !this.visible;
  }
  download()
  {
    window.location.href = '/dis/assets/resources/Batch_org_upload_template.csv';
  }
  selectfile(event) {
    const files = event.target.files;
    this.alerts = [];
    this.showprogress = false;
    if (files && files.length && files[0].type === 'text/csv') {
      if (files[0].size < 1048576) {
        this.selectedfile = files[0];
        this.selected = true;
      } else {
        this.selected = false;
        this.translateService.get('views.js.alerts.user.Selected file exceeds maximum file size of 1MB.').subscribe(res => {
          this.alerts.push({ type: 'danger', msg: res });
        });
      }
    } else {
      this.selected = false;
      this.translateService.get('views.js.alerts.organizations.Selecting file failed, make sure you choose a .csv file.').subscribe(res => {
        this.alerts.push({ type: 'danger', msg: res });
      });
    }
  }
  authorized()
  {
    return this.permission.isAdmin || this.permission.isOrganizationAdmin;

  }
  constructor(private commonService: CommonService, private translateService: TranslateService, private permission:PermissionService) { }
}
