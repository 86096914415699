<div id="userRequestList" [hidden]="!(authorized())">

    <ol class="breadcrumb breadcrumb-dis">
        <li><a [routerLink]="['']">{{'views.userrequest.Home' | translate}}</a></li>
        <li class="active">{{'views.userrequest.All pending users' | translate}}</li>
        <li class="logout"><a href="./oidc/logout">{{'views.userrequest.Log out' | translate}}</a></li>
    </ol>
    
    <div class="titleicon"><img src="assets/images/homepage/users.png" alt="{{'views.userrequest.Userrequest management' | translate}}"/></div>
    <h1>{{'views.userrequest.All pending users' | translate}} 
      
          <div class="loader"   *ngIf="isLoadingResults">
            <div class="dot" style="--dot-index:0;"></div>
            <div class="dot" style="--dot-index:1;"></div>
            <div class="dot" style="--dot-index:2;"></div>
          </div>  
         
    </h1>
    <div class="panel panel-default">

      <div class="panel-heading" style="display: flex; justify-content: end; align-items: center; background: #f5f5f5; width: 100%; background-color: #f5f5f5; border-color: #ddd;" >
        
        </div>

                   
              <table mat-table [dataSource]="filteredAndPagedIssues" class="example-table" matSort
                     matSortActive="lastname" matSortDisableClear matSortDirection="asc"
                     (matSortChange)="resetPaging()">
              
          
                <!-- Title Column -->
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef style="font-weight: bold; color: black;">{{'views.userrequest.Status'|translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <img style="width: 25px" *ngIf="row.accountStatus === 'suspended'"
                    src="assets/images/icons/bullet_red.png" alt="{{'views.user.suspended' | translate}}"
                    title="{{'views.user.suspended' | translate}}" />
                <img style="width: 25px" *ngIf="row.accountStatus === 'pending'"
                    src="assets/images/icons/bullet_green.png" alt="{{'views.user.active' | translate}}"
                    title="{{'views.user.active' | translate}}" />
                  </td>
                </ng-container>
          
              
                <ng-container matColumnDef="lastname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">{{'views.userrequest.Last name'|translate}}</th>
                  <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">{{row.sn}}</td>
                </ng-container>
          
               
                <ng-container matColumnDef="firstname">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                   {{'views.userrequest.First name'|translate}}</th>
                  <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">{{row.givenName}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                      {{'views.userrequest.Email'|translate}}
                    </th>
                    <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">{{row.mail}}</td>
                  </ng-container>
                  <ng-container matColumnDef="o.name">
                    <th mat-header-cell  *matHeaderCellDef mat-sort-header disableClear style="font-weight: bold; color: black;">
                      {{'views.userrequest.Organization'|translate}}
                    </th>
                    <td mat-cell (click)="navigateUserDetail(row)" *matCellDef="let row">{{row.memberOfOrg[0].name}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef  >
                     
                    <!-- <div *ngIf="row.userId.status  === 'suspended'">
                        <div confirm-activate onconfirm="activateOrganizationUser(organizationuser)"></div>
                        <div confirm-delete onconfirm="deleteOrganizationUser(organizationuser)"></div>
                    </div> -->
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div style="
                      margin: -6px -26px 0px;
                       width: 67px;
                       color: #333;
                       font-weight: 400;">
                        <app-confirm-delete (onconfirm)="deleteOrganizationUser(row)"></app-confirm-delete>
    </div>
                    </td>
                  </ng-container>
                   <!-- Item Description Column -->
    <ng-container matColumnDef="filter-status">
    <th mat-header-cell *matHeaderCellDef> 
     
       </th>
    </ng-container>
    
    <!-- Cost Description Column -->
    <ng-container matColumnDef="filter-lastname">
    <th mat-header-cell *matHeaderCellDef> 
      <div style="margin:0 -32px">

      <mat-form-field appearance="outline" style="width:83px !important">
        <input
        matInput
        (keyup)="applyFilterbyLastName($event.target.value)"
        
      />
      
    </mat-form-field> 
  </div>
  </th>
    </ng-container>
    <!-- Item Description Column -->
    <ng-container matColumnDef="filter-firstname">
    <th mat-header-cell *matHeaderCellDef>
      <div style="margin:0 -12px">
        <mat-form-field appearance="outline" style="width:76px !important">
          <input
            matInput
            (keyup)="applyFilterbyFirstName($event.target.value)"
           
          />
        </mat-form-field>
      </div>
      
    </th>
    </ng-container>
    
    <!-- Cost Description Column -->
    <ng-container matColumnDef="filter-email">
    <th mat-header-cell *matHeaderCellDef> 
  <mat-form-field appearance="outline" style="width:225px !important">
    <input
    matInput
    (keyup)="applyFilterbyEmail($event.target.value)"
    
  />
      
    </mat-form-field> </th>
    </ng-container>
          
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-header-row *matHeaderRowDef="['filter-status', 'filter-lastname','filter-firstname','filter-email']"
      class="example-second-header-row">
    </tr>
    
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [length]="resultsLength" [pageSize]="10"></mat-paginator>
          </div>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-default" (click)="gotoHomePage()">
                    <span class="glyphicon glyphicon-home" style="margin-right: 10px"></span>
                    {{'views.user.Home' | translate}}
                </button>
            </div>
            <div class="btn-group"  *ngIf="!(isHelpDeskAdmin())">
                <button type="button" class="btn btn-default" (click)="gotoUserRequestNewPage()">
                    <span class="glyphicon glyphicon-plus" style="margin-right: 10px"></span>
                    {{'views.user.Register user for organization' | translate}} 
                </button>
            </div>
      
        </div>
      </div>

    <app-terms-conditions></app-terms-conditions>