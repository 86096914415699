import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/permission.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from 'src/app/excel-export.service';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CurrentUser } from 'src/app/entities/current-user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  user: any;
  organizations: any=[];
  testData;
  myControl = new FormControl();
  selectedOrgName = '';
  alerts = [];
  scopestatus = false;
  filteredOptions: Observable<[]>;
  spinnerFlag = true;
  currentuser:CurrentUser;
  roles= [];
  activeGroup:string |null = null;

  constructor(private translateService: TranslateService, private excelService: ExportExcelService, private fb: FormBuilder, private permissionService: PermissionService, private commonService: CommonService, private router: Router) { }
  availableOrganizations()
  {
    this.commonService.getAllAvailableOrgs().subscribe(
      data => {
        this.spinnerFlag =false;
        if(data.body != null)
          {
                this.organizations = data.body;
                if(this.organizations.length ===0)
                {
                    const authToken = localStorage.getItem('authToken');
                    if(authToken!=null)
                    {
                      this.commonService.getCurrentUser().subscribe(data =>
                        {
                          this.availableOrganizations();
                          localStorage.setItem('currentUserDetails', JSON.stringify(data.body));

                        },
                        err =>{
                          this.router.navigate(['/login'])

                        }
                      )
                    }
                    else
                    {
                      this.router.navigate(['/login'])
                    }

                }
                this.commonService.getCurrentOrg().subscribe(
                  data => {
                    if(data.body !=null)
                      {
                    this.selectedOrgName = data.body[`name`];
                    this.commonService.selectedOrg = data.body;
                    }
                    else
                    {
                      if(this.organizations.length >0)
                      this.selectedOrgName = this.organizations[0].name;
                      this.commonService.selectedOrg = this.organizations[0];
                    }
                  }, error => {}
                );
                    this.filteredOptions = this.myControl.valueChanges
                      .pipe(
                        startWith({}),
                        map(user => user && typeof user === 'object' ? user.name : user),
                        map((name: string) => name ? this.filter(name) : this.organizations.slice())
                      );
                    }
      });
  }
  onGroupChange(group: string)
  {
    this.activeGroup =this.activeGroup === group ? null : group;
  }
  ngOnInit(): void {
      if(localStorage.getItem("currentUserDetails"))
        {
      this.currentuser = JSON.parse(localStorage.getItem("currentUserDetails"));
          if(this.currentuser[`peferences`]!=null)
            {
              if(this.currentuser['preferences'].preferredLanguage =='' ||this.currentuser['preferences'].preferredLanguage === null )
                this.commonService.setComponentLanguage("EN");
              else
                this.commonService.setComponentLanguage(this.currentuser['preferences'].preferredLanguage);
            }
          if(this.currentuser['authzRoles']!=null &&this.currentuser['authzRoles'].length>0)
            {
              for (let person of this.currentuser['authzRoles']) {
                this.roles.push(person['_refResourceId']);
            }

           }
          if(this.currentuser['adminOfOrg'].length >0)
          {
            this.roles.push('adminOfOrg')
          }
         for(let roles of this.roles)
          {
          this.permissionService.isAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('ciam-admin') >= 0 : false;
        //  this.permissionService.isRoleAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisRoleAdmin') >= 0 : false;
       //   this.permissionService.isRoleAssignmentAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
         // this.permissionService.isContractAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisCctAdmin') >= 0 : false;
          //this.permissionService.isUserAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
          //this.permissionService.isBatchAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisBatchAdmin') >= 0 : false;
          this.permissionService.isOrganizationAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('adminOfOrg') >= 0 : false;
          //this.permissionService.isGroupAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisGrpAdmin') >= 0 : false;
          //this.permissionService.isGroupAssignAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisGrpAssign') >= 0 : false;
          //this.permissionService.isServiceAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisSrvAdmin') >= 0 : false;
          this.permissionService.isHelpDeskAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('gsd-gas-admin') >= 0 : false;
         // this.permissionService.isStatsAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisStatsAdmin') >= 0 : false;
          //this.permissionService.isSPregistrarAdmin = (this.roles !== undefined && this.roles.length > 0) ? this.roles.indexOf('DisSPregistrarAdmin') >= 0 : false;
      }

        this.currentuser = JSON.parse(localStorage.getItem("currentUserDetails"));

         this.availableOrganizations();



      }

    }

    // this.commonService.getAllAvailableOrgs().subscribe(
    //   data => {
    //     this.organizations = data.body;
    //     this.filteredOptions = this.myControl.valueChanges
    //       .pipe(
    //         startWith({}),
    //         map(user => user && typeof user === 'object' ? user.name : user),
    //         map((name: string) => name ? this.filter(name) : this.organizations.slice())
    //       );
    //   }, error => {
    //     this.organizations =[];
    //   }
    //   )

  filter(name: string) {
    const filterValue = name.toLowerCase();
  return this.organizations.filter(option => String(option.technicalId).toLowerCase().indexOf(filterValue ) > -1 ||
  option.name.toLowerCase().indexOf(filterValue ) > -1);

  }
  hasAlladminTypes()
  {
    return this.permissionService.isAdmin || this.permissionService.isOrganizationAdmin || this.permissionService.isHelpDeskAdmin;

  }

  hasCIAMAdmin()
  {
    return this.permissionService.isAdmin;

  }
  hasOrgAdminandAdmin()
  {
        return this.permissionService.isAdmin || this.permissionService.isOrganizationAdmin;
  }
  hasHelpDeskAdminandAdmin()
  {
    return this.permissionService.isAdmin || this.permissionService.isHelpDeskAdmin;

  }
  authorizedUsers() {
    return this.permissionService.isAdmin || this.permissionService.isOrganizationAdmin || this.permissionService.isHelpDeskAdmin;
  }
  authorizedRoles() {
    return this.permissionService.isAdmin || this.permissionService.isOrganizationAdmin || this.permissionService.isHelpDeskAdmin;
  }
  authorizedRoleAssign() {
    return this.permissionService.isAdmin || this.permissionService.isRoleAssignmentAdmin;
  }
  authorizedAll() {
    return this.permissionService.isAdmin;
  }
  authorizedAllUsers()
  {
    return this.permissionService.isAdmin || this.permissionService.isHelpDeskAdmin;
  }
  authorizedForReports()
  {
    return this.permissionService.isAdmin || this.permissionService.isOrganizationAdmin || this.permissionService.isHelpDeskAdmin;
  }

  authorizedOrganizations()
  {
    return  this.permissionService.isAdmin;

  }
  authorizedHelpDesk()
  {
    return  this.permissionService.isAdmin || this.permissionService.isHelpDeskAdmin;

  }
  authorizedServices()
  {
    return  this.permissionService.isAdmin ;

  }
  authorizedContracts()
  {
    return this.permissionService.isAdmin || this.permissionService.isContractAdmin;
  }
  gotoUserRequestNewPage() {
    this.commonService.titleSub = 'a new';
    this.router.navigate(['userrequest/new'])
      .then(nav => {
      // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoUserListPage() {
    this.router.navigate(['user/list'])
      .then(nav => {
       // true if navigation is successful
      }, err => {
        // when there's an error
      });
  }
  gotoRoleNewPage() {
    this.router.navigate(['role/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
        // when there's an error
      });
  }
  gotoRoleAssignmentNewPerOrgPage() {
    this.router.navigate(['roleassignment/new'])
      .then(nav => {
       // true if navigation is successful
      }, err => {
       // when there's an error
      });
  }
  gotoRoleAssignmentNewPage() {
    this.router.navigate(['roleassignment/new/adminRole'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoUserRequestListPage() {
    this.router.navigate(['userrequest/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoRoleListPage() {
    this.router.navigate(['role/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoRoleAssignmentListPage() {
    this.router.navigate(['roleassignment/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  gotoAllRoleAssignmentListPage() {
    this.router.navigate(['roleassignment/list/all'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  updatePortCode(event: MatAutocompleteSelectedEvent) {

    if (event.option.value !== undefined) {
      this.selectedOrgName = event.option.value.name;
      this.commonService.selectedOrg = event.option.value;
      this.myControl.setValue("");
      this.commonService.setCurrentOrg(event.option.value.id).subscribe(data => {
         ;
      });
    }

  }

  gotoUserListAllPage() {
    this.router.navigate(['user/list/all'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });

  }
  gotoUserRequestListAllPage() {
    this.router.navigate(['userrequest/list/all'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  displayFn(user): string {
   return user ? user.name : '' ;
  }
  gotoUserBatchPage() {
    this.router.navigate(['usersBatch'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
  // service management routing
  gotoViewServicesPage() {
    this.router.navigate(['services/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  gotoAddServicePage() {
    this.router.navigate(['services/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  gotoViewServiceDetailsPage() {
    this.router.navigate(['viewservice/details'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  // help desk routing
  gotoSendPasswordResetLinkPage() {
    this.router.navigate(['passwordreset'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }

  gotoShowUserEntriesPage() {
    this.router.navigate(['helpdesk'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
  }
   // Org routing
  gotoOrganizationListPage() {
    this.router.navigate(['organization/list'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
  }
  gotoOrganizationBatchPage() {
    this.router.navigate(['organization/batch'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
    }
  gotoOrganizationNewPage() {
    this.router.navigate(['organization/new'])
    .then(nav => {
       // true if navigation is successful
    }, err => {
       // when there's an error
    });
    }
    gotoContractListPage() {
      this.router.navigate(['contract/list'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    gotoContractNewPage() {
      this.router.navigate(['contract/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    // federation routing
    gotoFederatedNewPage() {
      this.router.navigate(['federation/new'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
    // federation details routing
    gotoFederatedUpdateDetailsPage() {
      this.router.navigate(['federation/details'])
      .then(nav => {
         // true if navigation is successful
      }, err => {
         // when there's an error
      });
    }
   jsonToCSV(jsonData: any[]): string {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));

    // Loop over the rows
    for (const row of jsonData) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }
  downloadCSV(data,fileName) {
      const csvData = this.jsonToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', fileName+'.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  }
  sendReport() {
    const name ="user";
    const orgid= this.commonService.selectedOrg[`id`];
    this.commonService.sendReports(name,orgid).subscribe((data) => {
      this.translateService.get(' report is being generated and will be sent to you by email.').subscribe(res => {
        this.alerts.push({type: 'info', msg: "User "+res});
      });
    },err=>{
      this.alerts.push({type: 'danger', msg:"User Report - "+ "Something went wrong "})
    }
  );
  }

  // send all email otp report
  sendOrgEmailOtpReport() {
    const name ="emailOtp";
    const orgid= this.commonService.selectedOrg[`id`];
    this.commonService.sendReports(name,orgid).subscribe((data: any) => {

      this.translateService.get(' report is being generated and will be sent to you by email.').subscribe(res => {
        this.alerts.push({type: 'info', msg:"Email OTP"+ res});


      },err=>{
        this.alerts.push({type: 'danger', msg:"Email OTP Report - "+ "Something went wrong "})
      }
    );


    });
  }
  closeAlert(index) {
    this.alerts.splice(index, 1);
  }
  // send application report
  sendApplicationReport() {
    const name ="application";
    const orgid= this.commonService.selectedOrg[`id`];
    this.commonService.sendReports(name,orgid).subscribe((data: any) => {

          this.translateService.get(' report is being generated and will be sent to you by email.').subscribe(res => {
            this.alerts.push({type: 'info', msg: "Application"+res});
          });
    },
    err=>{
      this.alerts.push({type: 'danger', msg: "Application Report - Something went wrong"});

    }
  );
  }
  // send Admin Application Report
  sendApplicationAdminReport() {
    const name ="applicationAdmin";
    const orgid= this.commonService.selectedOrg[`id`];
    this.commonService.sendReports(name,orgid).subscribe((data: any) => {


          this.translateService.get(' report is being generated and will be sent to you by email.').subscribe(res => {
            this.alerts.push({type: 'info', msg: "Application Admin"+res});
          });
        },err=>{
          this.alerts.push({type: 'danger', msg: "Application Admin Report - Something went wrong"});

        }

      );


  }
  // send total users report
  sendTotalUsersCountReport() {
    const name ="totalCount";
    const orgid= this.commonService.selectedOrg[`id`];
    this.commonService.sendReports(name,orgid).subscribe((data: any) => {

          this.translateService.get(' report is being generated and will be sent to you by email.').subscribe(res => {
            this.alerts.push({type: 'info', msg: "Total Users Count"+res});
          });

    },
    err =>{
      this.alerts.push({type: 'danger', msg: "Total Users Count Report  - Something went wrong"});

    }
  );
  }
}
